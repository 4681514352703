import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import makeResponsiveSizes from 'src/lib/utilities/makeResponsiveSizes';

import { useAppContext } from 'src/context/AppContext';
import AppLayout from 'src/layouts/app/AppLayout';
import Logo from 'src/images/logo/logo.svg';
import Heading from 'src/components/content/heading/Heading';

import styles from 'src/scss/pages/Home.module.scss';

const HomePage = ({ data }) => {
  const appContext = useAppContext();

  const clearHeaderBreaks = appContext.breakpoint === 'sm';
  const clearParagraphBreaks =
    appContext.breakpoint === 'sm' || appContext.breakpoint === 'md';

  const heroHeadingSizes = makeResponsiveSizes({
    xs: 5,
    sm: 4,
    md: 3,
    lg: 1,
  });

  const sectionHeadingSizes = makeResponsiveSizes({
    xs: 5,
    xl: 4,
  });

  return (
    <AppLayout theme="light">
      <div className={styles.hero}>
        <div className={styles.hero__logo}>
          <Logo />
        </div>
        <div className={styles.hero__content}>
          <Heading
            content={data.wpPage.content}
            noBreaks={clearHeaderBreaks}
            size={heroHeadingSizes[appContext.breakpoint]}
            theme="dark"
            weight="regular"
          />
        </div>
      </div>
      <div className={styles.pageSections}>
        <div className={styles.pageSections__section}>
          <Heading
            content={data.wpPage.fieldsHome.homeSectionOne}
            noBreaks={clearParagraphBreaks}
            size={sectionHeadingSizes[appContext.breakpoint]}
            theme="dark"
            weight="thin"
          />
        </div>
        <div className={styles.pageSections__section}>
          <Heading
            content={data.wpPage.fieldsHome.homeSectionTwo}
            noBreaks={clearParagraphBreaks}
            size={sectionHeadingSizes[appContext.breakpoint]}
            theme="dark"
            weight="thin"
          />
        </div>
        <div className={styles.pageSections__section}>
          <Heading
            content={data.wpPage.fieldsHome.homeSectionThree}
            noBreaks={clearParagraphBreaks}
            size={sectionHeadingSizes[appContext.breakpoint]}
            theme="dark"
            weight="thin"
          />
        </div>
      </div>
    </AppLayout>
  );
};

HomePage.propTypes = {
  data: PropTypes.shape({
    wpPage: PropTypes.shape({
      content: PropTypes.string.isRequired,
      fieldsHome: PropTypes.shape({
        homeSectionOne: PropTypes.string.isRequired,
        homeSectionTwo: PropTypes.string.isRequired,
        homeSectionThree: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query HomePageQuery {
    wpPage(slug: { eq: "home" }) {
      content
      fieldsHome {
        homeSectionOne
        homeSectionThree
        homeSectionTwo
      }
    }
  }
`;

export default HomePage;
