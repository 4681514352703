import is from 'is_js';

const makeResponsiveSettings = (settings) => {
  const responsiveSettings = {};
  const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];

  if (!is.object(settings) || is.null(settings)) {
    throw new Error('makeResponsiveSettings must be passed a settings object');
  }

  if (is.undefined(settings.xs)) {
    throw new Error(
      'makeResponsiveSettings must be passed a setting for the "xs" breakpoint'
    );
  }

  Object.keys(settings).forEach((setting) => {
    if (!breakpoints.includes(setting)) {
      throw new Error('invalid setting passed to makeResponsiveSettings');
    }
  });

  breakpoints.forEach((breakpoint, index) => {
    if (
      !is.number(settings[breakpoint]) &&
      !is.undefined(settings[breakpoint])
    ) {
      throw new Error('invalid setting passed to makeResponsiveSettings');
    } else if (settings[breakpoint] === undefined) {
      responsiveSettings[breakpoint] =
        responsiveSettings[breakpoints[index - 1]];
    } else {
      responsiveSettings[breakpoint] = settings[breakpoint];
    }
  });

  return responsiveSettings;
};

export default makeResponsiveSettings;
